<template>
  <div>
    <title-bar title="Galerie bearbeiten">
      <router-link slot="right" to="/galleries" class="button is-primary">
        <b-icon
          :icon="$func.getIcon('back').icon"
          :pack="$func.getIcon('back').pack"
          class="mr-1"
        />
        Galerie Übersicht
      </router-link>
    </title-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          title="Details zur Veranstaltung"
          :icon="$func.getIcon('gallery').icon"
          :icon-pack="$func.getIcon('gallery').pack"
          class="tile is-child"
        >
          <form @submit.prevent="submitGallery">
            <b-field label="ID" horizontal>
              <b-input v-model="gallery.id" custom-class="is-static" readonly />
            </b-field>

            <b-field label="Erstellt am" horizontal>
              <b-input
                v-model="createdReadable"
                custom-class="is-static"
                readonly
              />
            </b-field>

            <hr />

            <b-field label="Name" horizontal>
              <b-input
                v-model="gallery.name"
                placeholder="e.g. Party XYZ"
                minlength="2"
                required
              />
            </b-field>

            <b-field label="Deaktiviert" horizontal>
              <b-switch v-model="gallery.disable">
                {{ gallery.disable ? "Ja" : "Nein" }}
              </b-switch>
            </b-field>

            <hr />

            <b-field horizontal>
              <b-button
                type="is-primary"
                native-type="submit"
                :icon-left="$func.getIcon('save').icon"
                :icon-pack="$func.getIcon('save').pack"
              >
                Galerie bearbeiten
              </b-button>
            </b-field>
          </form>
          <b-loading :is-full-page="false" :active="isLoading.form1" />
        </card-component>

        <card-component
          title="Neue Bilder hochladen"
          :icon="$func.getIcon('gallery').icon"
          :icon-pack="$func.getIcon('gallery').pack"
          class="tile is-child"
        >
          <b-upload multiple drag-drop @input="uploadFiles">
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon
                    :icon="$func.getIcon('upload').icon"
                    :pack="$func.getIcon('upload').pack"
                    size="is-large"
                  >
                  </b-icon>
                </p>
                <p>
                  Dateien hier per Drag &amp; Drop ablegen um Upload zu starten
                </p>
              </div>
            </section>
          </b-upload>
          <b-loading :is-full-page="false" :active="isLoading.form2" />
        </card-component>
      </tiles>
      <tiles>
        <card-component
          :title="`Aktuelle Bilder (${gallery.photos.length})`"
          :icon="$func.getIcon('gallery').icon"
          :icon-pack="$func.getIcon('gallery').pack"
          class="tile is-child"
        >
          <div class="columns is-multiline is-mobile">
            <div v-for="p in gallery.photos" :key="p.id" class="column is-3">
              <b-image :src="p.img_base64" ratio="3by2" />
              <div class="has-text-centered pt-3">
                <b-button
                  :icon-left="$func.getIcon('delete').icon"
                  :icon-pack="$func.getIcon('delete').pack"
                  type="is-danger"
                  size="is-small"
                  @click="removeFile(p)"
                >
                  Bild Entfernen
                </b-button>
              </div>
            </div>
          </div>
          <b-loading :is-full-page="false" :active="isLoading.photos" />
        </card-component>
      </tiles>
    </section>

    <b-loading :is-full-page="true" :active="isLoading.all" />
  </div>
</template>

<script>
import api from "@/utils/api.js";
import TitleBar from "@/components/TitleBar";
import Tiles from "@/components/Tiles";
import CardComponent from "@/components/CardComponent";

export default {
  name: "GalleryEdit",
  components: {
    CardComponent,
    Tiles,
    TitleBar
  },
  data() {
    return {
      isLoading: {
        all: false,
        form1: false,
        form2: false,
        photos: false
      },
      createdReadable: null,
      gallery: {
        id: null,
        name: null,
        photos: []
      }
    };
  },
  watch: {
    id(newValue) {
      if (!parseInt(newValue)) {
        this.gallery = this.getClearFormObject();
      } else {
        this.loadData();
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getClearFormObject() {
      return {
        id: null,
        name: null,
        photos: []
      };
    },
    loadData() {
      if (Number.isInteger(parseInt(this.$route.params.id))) {
        this.isLoading.all = true;
        api
          .get(`/galleries/${this.$route.params.id}?with=photos`)
          .then(r => {
            this.gallery = r.data;
            this.createdReadable = this.$moment(r.data.created_at).format();
          })
          .finally(() => (this.isLoading.all = false));
      }
    },
    reloadPhotos() {
      this.isLoading.photos = true;
      this.$buefy.toast.open({
        message: "Lade Bilder neu...",
        queue: false
      });
      api
        .get(`/galleries/${this.gallery.id}/photos?top=false`)
        .then(r => {
          this.gallery.photos = r.data;
        })
        .finally(() => {
          this.isLoading.photos = false;
          this.isLoading.form2 = false;
        });
    },
    uploadFiles(value) {
      var counter = 0;
      var that = this;
      this.isLoading.form2 = true;
      value.forEach(f => {
        if (f.type !== "image/png" && f.type !== "image/jpeg") {
          this.$buefy.toast.open({
            message: `Datei ${f.name} wird nicht hochgeladen, Dateityp ist nicht erlaubt!`,
            type: "is-danger",
            queue: true
          });
        } else {
          var reader = new FileReader();
          reader.addEventListener(
            "load",
            function() {
              api.get("/sanctum/csrf-cookie").then(() => {
                api
                  .post(`/galleries/${that.gallery.id}/photos`, {
                    img_base64: this.result
                  })
                  .then(() => {
                    counter += 1;
                    that.$buefy.toast.open({
                      message: `Datei ${f.name} wird hochgeladen...`,
                      queue: false
                    });

                    if (counter === value.length) {
                      that.reloadPhotos();
                    }
                  });
              });
            },
            false
          );
          reader.readAsDataURL(f);
        }
      });
    },
    removeFile(photo) {
      this.isLoading.photos = true;
      api
        .delete(`/galleries/photos/${photo.id}`)
        .then(() => {
          this.$buefy.snackbar.open({
            message: "Bild wurde entfernt",
            queue: false
          });
          this.reloadPhotos();
        })
        .catch(() => (this.isLoading.photos = false));
    },
    submitGallery() {
      this.isLoading.form1 = true;
      api
        .put(`/galleries/${this.gallery.id}`, this.gallery)
        .then(() => {
          this.$buefy.snackbar.open({
            message: "Galerie bearbeitet",
            queue: false
          });
        })
        .finally(() => (this.isLoading.form1 = false));
    }
  }
};
</script>
